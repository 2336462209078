<template>
  <div>
    扫二维码后需要展示的信息
    <p>{{ userId }}</p>
  </div>
</template>

<script>
export default {
    data(){
        return{
            userId:this.$route.query.userId,
        }
    }
}
</script>

<style>

</style>